import React, { createRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { graphql } from "gatsby";
import bowser from "bowser";
import { AnimationOnScroll } from "react-animation-on-scroll";
import lottie from "lottie-web";
import Records from "../assets/Tech-Log/icon-validated-records.svg";
import Investment from "../assets/Tech-Log/icon-return-on-investment.svg";
import CAMP from "../assets/camp-logo2x.png";
import AMOS from "../assets/amos_logo.png";
import RocketRoute from "../assets/rocketroute_logo.png";
import Avianis from "../assets/Avianis.svg";
import Leon from "../assets/Leon.svg";
import Centrik from "../assets/centrik_logo.png";
import FleetOverview from "../assets/CAMO/highly-experienced.svg";
import FleetStatus from "../assets/Tech-Log/tl-fleet-status-dashboard.svg";
import History from "../assets/Tech-Log/tl-aircraft-history.svg";
import Validation from "../assets/Tech-Log/tl-data-validation.svg";
import Regulators from "../assets/Tech-Log/tl-regulators.svg";
import Fleet from "../assets/Tech-Log/tl-fleet.png";
import TechCard from "../components/TechLog/techCard";
import Tick from "../assets/icon-tick.svg";
import TLDashSafariAnimation from "../assets/TLDashSafari.json";
import TLDashAnimation from "../assets/TLDashAll.json";
import PostflightAnimation from "../assets/postflight.json";
import Seo from "../components/SEO";
import Started from "../components/Layout/started";
import AlsoInterestedIn from "../components/Layout/alsoInterestedIn";
import Seamless from "../components/Layout/seamlessIntegration";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";
import * as styles from "./taskcards.module.less";
import * as Scroll from "react-scroll";

const { scroller } = Scroll;

const FleetStatusCard = {
  img: FleetStatus,
  title: "Fleet status dashboard",
  text: "With a clear overview of your entire fleet, you'll know where your aircraft are and see upcoming maintenance.",
};

const HistoryCard = {
  img: History,
  title: "Full aircraft history",
  text: "Instantly available through the ops dashboard, the aircraft’s full history is available when you need it.",
};

const ValidationCard = {
  img: Validation,
  title: "Smart data validation",
  text: "Data entered by pilots and engineers is validated instantly. Notifications and records are immediately dispatched to your ops team on trip close-out.",
};

const cardsArray = [FleetStatusCard, HistoryCard, ValidationCard];

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
`;

const tick = (
  <div className={styles.tick}>
    <img src={Tick} alt="tick" />
  </div>
);

const TextWrapper = styled.div`
  @media screen and (max-width: 770px) {
    margin: 0 30px;
  }
  @media screen and (max-width: 450px) {
    margin: 0 20px;
  }
`;

const TickWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.17;
  color: #242d41;
  margin-bottom: 40px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.9);
  margin-bottom: 20px;
`;

const TickSection = styled.div`
  display: flex;
  margin-bottom: 12px;
  font-size: 16px;
  letter-spacing: 0.02px;
  color: #242d41;
  line-height: 1.75;
`;

const GradientBackground = styled.div`
  padding-bottom: 100px;
  background-image: linear-gradient(to top, #f8fcff, #fff);
`;

const SectionWrapper = styled.div`
  max-width: 1440px;
  margin: auto;
`;

const RegulatorImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 770px) {
    img {
      width: 50%;
    }
  }
  @media screen and (max-width: 450px) {
    img {
      width: 100%;
    }
  }
`;

const TechLog = (props) => {
  const techlogAnimationRef = createRef();
  const postflightAnimationRef = createRef();

  const cards = cardsArray.map((card, key) => (
    <Col md={4} className={`${styles.techCardCol} ${pageStyles.coliPad}`}>
      <AnimationOnScroll
        animateOnce
        animateIn={
          key === 0
            ? "animate__fadeInLeft"
            : key === 1
            ? "animate__fadeInUp"
            : "animate__fadeInRight"
        }
        offset={0}
      >
        <TechCard
          key={card.title}
          img={card.img}
          title={card.title}
          text={card.text}
        />
      </AnimationOnScroll>
    </Col>
  ));

  const { mainImage } = props.data;

  const seamlessCards = [
    {
      img: RocketRoute,
      title: "RocketRoute",
    },
    {
      img: AMOS,
      title: "AMOS",
    },
    {
      img: Centrik,
      title: "Centrik",
    },
    {
      img: CAMP,
      title: "CAMP",
    },
    {
      img: Avianis,
      title: "Avianis",
    },
    {
      img: Leon,
      title: "Leon",
    },
  ];

  useEffect(() => {
    const safari = bowser.name === "Safari";
    const TLAnimation = lottie.loadAnimation({
      container: techlogAnimationRef.current,
      animationData: safari ? TLDashSafariAnimation : TLDashAnimation,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    const PostflightAnim = lottie.loadAnimation({
      container: postflightAnimationRef.current,
      animationData: PostflightAnimation,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
  }, []);

  return (
    <Layout>
      <Seo
        title="Tech Log"
        description="Make the final step to paperless operations"
      />
      <PageHeader
        header="The Aircraft Tech Log. <br /><span>Modernised.</span>"
        subHeader="Make the final step to paperless operations with our electronic Tech Log."
        linkText="Schedule a demo"
        bigHeader
        bigImage={mainImage?.childImageSharp?.fluid}
        imageWithShadow
        whiteLink
        link="#schedule_demo_form"
        handleLinkScroll={(e) => {
          e.preventDefault();
          scroller.scrollTo("schedule_demo_form", {
            duration: 800,
            smooth: true,
            offset: -60,
          });
        }}
      />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={{ span: 6 }} className={pageStyles.coliPad}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              offset={0}
            >
              <TextWrapper>
                <h3 className={styles.title}>
                  Gain a complete oversight of your fleet
                </h3>
                <Text>
                  With validated, real-time, records coming in from both flight
                  crew and maintenance, your fleet status will always be up to
                  date and accurate.
                </Text>
                <Text>
                  Realise the power that digital records have to offer;
                  transform your operation by eliminating transcription errors
                  and manual processes.
                </Text>
                <div>
                  <TickSection>
                    {tick} Gain an instant overview of your aircraft, their
                    locations and maintenance statuses.
                  </TickSection>
                  <TickSection>
                    {tick} Be alerted for issues across the fleet, in real-time,
                    and resolve them quickly.
                  </TickSection>
                  <TickSection>
                    {tick} Fully digital workflows for crew and maintenance,
                    capturing vital information without the mistakes.
                  </TickSection>
                </div>
              </TextWrapper>
            </AnimationOnScroll>
          </Col>
          <Col md={6} className={`${pageStyles.coliPad}`}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInRight"
              offset={0}
            >
              <RegulatorImage>
                <img src={Fleet} alt="Tech Log Fleet Details" />
              </RegulatorImage>
            </AnimationOnScroll>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={10}>
            <Row>
              <Col>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h3 className={styles.title}>We will help you achieve</h3>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
            </Row>
            <Row className={styles.topCardsParent}>
              <Col sm={4}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInUp"
                  offset={0}
                >
                  <div className={styles.topCardWrapper}>
                    <img src={FleetOverview} alt="fleet overview" />
                    <span>Complete oversight of your fleet, in real-time</span>
                  </div>
                </AnimationOnScroll>
              </Col>
              <Col sm={4}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  <div className={styles.topCardWrapper}>
                    <img src={Records} alt="validated records" />
                    <span>Validated records, correct first time</span>
                  </div>
                </AnimationOnScroll>
              </Col>
              <Col sm={4}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInRight"
                  offset={0}
                >
                  <div className={styles.topCardWrapper}>
                    <img src={Investment} alt="return on investment" />
                    <span>An incredible return on investment</span>
                  </div>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={12}>
            <Row className={styles.titleRow}>
              <Col md={12}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h3 className={styles.title}>Paper free. Error free.</h3>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
              <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h4 className={styles.subTitle}>
                      Reduce costly paper logs and eliminate transcription
                      errors. Our intelligent validation catches mistakes before
                      records are completed, ensures crew compliance, and
                      automates away repetitive data entry.
                    </h4>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
            </Row>
            <Row>
              <Col lg={7} md={6} className={pageStyles.coliPad}>
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInLeft"
                  offset={0}
                >
                  <div ref={techlogAnimationRef} />
                </AnimationOnScroll>
              </Col>
              <Col
                lg={4}
                md={5}
                className={`${pageStyles.coliPad} ${styles.margin50}`}
              >
                <AnimationOnScroll
                  animateOnce
                  animateIn="animate__fadeInRight"
                  offset={0}
                >
                  <p className={styles.description}>
                    This ensures that your aircraft records are up to date,
                    accurate and always available wherever you need them.
                  </p>
                  <p className={`${styles.description} ${styles.quote}`}>
                    “Using the TrustFlight Tech Log allows me to focus on the
                    flying instead of the paperwork. With a simple overview of
                    aircraft status, I can brief ahead of even arriving at the
                    aircraft, and features such as the built-in MEL and
                    electronic maintenance sign-offs are superb. All of this
                    makes my life much easier and reduces the risk of delays due
                    to paperwork.”
                  </p>
                  <p className={`${styles.description} ${styles.name}`}>
                    Chris Forster <span>Pilot</span>
                  </p>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <GradientBackground>
        <SectionWrapper>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          >
            <Col md={{ span: 5 }} className={pageStyles.coliPad}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInLeft"
                offset={0}
              >
                <TextWrapper>
                  <Title>Recognised by regulators globally</Title>
                  <Text>
                    The Tech Log is in use across a variety of operations around
                    the world, including everything from Airlines, to Cargo,
                    Charter, and Private Operators.
                  </Text>
                  <Text>
                    Our collection of template documentation, paired with our
                    experienced team, make gaining regulatory acceptance of the
                    system straightforward. We are also the only dedicated
                    Electronic Tech Log provider to have gained acceptance by
                    EASA through a letter of No Technical Objection (NTO) after
                    extensive evaluation.
                  </Text>
                  <div>
                    <Text>We have customers operating under:</Text>
                    <TickWrapper>
                      <TickSection>{tick} CAA (Isle of Man)</TickSection>
                      <TickSection>{tick} CAA (United Kingdom)</TickSection>
                      <TickSection>{tick} CASA (Australia)</TickSection>
                      <TickSection>{tick} EASA</TickSection>
                      <TickSection>{tick} FAA (United States)</TickSection>
                      <TickSection>{tick} GCAA (UAE)</TickSection>
                      <TickSection>{tick} Transport Canada</TickSection>
                      <TickSection>{tick} Transport Malta</TickSection>
                    </TickWrapper>
                  </div>
                </TextWrapper>
              </AnimationOnScroll>
            </Col>
            <Col md={5} className={`${pageStyles.coliPad}`}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInRight"
                offset={0}
              >
                <RegulatorImage>
                  <img src={Regulators} alt="regulators" />
                </RegulatorImage>
              </AnimationOnScroll>
            </Col>
          </Row>
        </SectionWrapper>
      </GradientBackground>
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={10} md={12}>
            <Row className={styles.titleRow}>
              <Col md={12}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h3 className={styles.title}>
                      Powerful operations support
                    </h3>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
              <Col md={{ span: 8, offset: 2 }}>
                <CenteredDiv>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <h4 className={styles.subTitle}>
                      From one aircraft to hundreds, our tools will aid in your
                      operations and provide the information you need, when you
                      need it.
                    </h4>
                  </AnimationOnScroll>
                </CenteredDiv>
              </Col>
            </Row>
            <Row className={styles.cardsHolder}>{cards}</Row>
          </Col>
        </Row>
      </Container>
      <GradientSection>
        <Container>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          >
            <Col md={12}>
              <Row className={styles.titleRow}>
                <Col md={12}>
                  <CenteredDiv>
                    <AnimationOnScroll
                      animateOnce
                      animateIn="animate__fadeInDown"
                      offset={0}
                    >
                      <h3 className={styles.title}>
                        Designed for operators and pilots. Continuously
                        evolving.
                      </h3>
                    </AnimationOnScroll>
                  </CenteredDiv>
                </Col>
                <Col md={{ span: 8, offset: 2 }}>
                  <CenteredDiv>
                    <AnimationOnScroll
                      animateOnce
                      animateIn="animate__fadeInUp"
                      offset={0}
                    >
                      <h4 className={styles.subTitle}>
                        Our Tech Log has been designed in close cooperation with
                        airlines, operators, pilots, and engineers, ensuring
                        that it meets the needs of today’s businesses. We never
                        stop improving though and are constantly making new
                        features available to our customers.
                      </h4>
                    </AnimationOnScroll>
                  </CenteredDiv>
                </Col>
              </Row>
              <Row className={styles.benefitsRow}>
                <Col
                  lg={{ span: 4, offset: 1 }}
                  md={{ span: 5, offset: 1 }}
                  className={pageStyles.coliPad}
                >
                  <div className={styles.tickParent}>
                    <AnimationOnScroll
                      animateOnce
                      animateIn="animate__fadeInLeft"
                      offset={0}
                    >
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Access the complete history of your aircraft instantly
                          via the ops dashboard
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Receive notifications and records promptly upon trip
                          close-out
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>Record journey, maintenance and flight logs</span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Automate data import from popular flight planning,
                          scheduling and maintenance systems
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>Scan receipts and monitor fuel costs</span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Quickly defer defects using our powerful digital MEL
                          solution
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Work offline or online, from anywhere in the world. Or
                          36,000 ft above it.
                        </span>
                      </div>
                    </AnimationOnScroll>
                  </div>
                </Col>
                <Col lg={7} md={6} className={pageStyles.coliPad}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInRight"
                    offset={0}
                  >
                    <div
                      ref={postflightAnimationRef}
                      className={styles.screenImg}
                    />
                  </AnimationOnScroll>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </GradientSection>
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={10} md={12}>
            <Seamless
              title="Seamless integrations"
              text="Our Tech Log integrates with the most popular services to ensure that you only have to enter information once. Data such as flight plans and maintenance requirements are brought in, with utilisation, defects and maintenance sign offs sent out seamlessly."
              cards={seamlessCards}
              externalAddMore
            />
          </Col>
        </Row>
        <a id="schedule_demo_form" aria-label="Schedule a demo form" />
        <Started hubSpotFormId="31b02ccb-1b41-4cb0-9bf7-37cb90137bae" />
      </Container>
      <AlsoInterestedIn page="tech" />
    </Layout>
  );
};
export default TechLog;

export const query = graphql`
  query TechLogQuery {
    mainImage: file(relativePath: { eq: "Tech-Log/tech-log-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paperFreeImg: file(relativePath: { eq: "Tech-Log/tech-log-screen-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    benefitsImg: file(relativePath: { eq: "Tech-Log/tech-log-screen-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
